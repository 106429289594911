/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    div: "div",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    annotation: "annotation",
    msub: "msub",
    mn: "mn",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "¿Qué es el controlador?"), "\n", React.createElement(_components.p, null, "Ha llegado el momento de definir que es exactamente este controlador que hemos visto."), "\n", React.createElement(_components.p, null, "Un controlador va a ser por supuesto un sistema estático o dinámico que creamos nosotros, veamos como lo definimos:"), "\n", React.createElement(_components.p, null, "Es una función que multiplica a nuestro error. Puede ser estática o dinámica:"), "\n", React.createElement(_components.p, null, "Estática, donde K es una contante que multiplica al error:"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, React.createElement(_components.span, {
    className: "katex-display"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML",
    display: "block"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "u"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "K"), React.createElement(_components.mo, null, "⋅"), React.createElement(_components.mi, null, "e")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "u=K \\cdot e")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.43056em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.68333em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.07153em"
    }
  }, "K"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "⋅"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.43056em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e")))))), "\n", React.createElement(_components.p, null, "Dinámica en tiempo discreto, en este caso es un sistema de 1º orden, es decir que depende únicamente del error en ese instante (ek) y el anterior(ek-1), podría ser de un orden superior donde iría aumentando el número de instantes anteriores que usaríamos. En este caso tenemos dos constantes K1 y K2:"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, React.createElement(_components.span, {
    className: "katex-display"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML",
    display: "block"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "u"), React.createElement(_components.mo, null, "="), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "K"), React.createElement(_components.mn, null, "1")), React.createElement(_components.mo, null, "⋅"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "k")), React.createElement(_components.mo, null, "+"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "K"), React.createElement(_components.mn, null, "2")), React.createElement(_components.mo, null, "⋅"), React.createElement(_components.msub, null, React.createElement(_components.mi, null, "e"), React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "k"), React.createElement(_components.mo, null, "−"), React.createElement(_components.mn, null, "1")))), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "u=K_1 \\cdot e_k+K_2 \\cdot e_{k-1}")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.43056em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "u"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.83333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.07153em"
    }
  }, "K"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.30110799999999993em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.5500000000000003em",
      marginLeft: "-0.07153em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "1")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "⋅"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.73333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.33610799999999996em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.5500000000000003em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.03148em"
    }
  }, "k")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "+"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.83333em",
      verticalAlign: "-0.15em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.07153em"
    }
  }, "K"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.30110799999999993em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.5500000000000003em",
      marginLeft: "-0.07153em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "2")))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.15em"
    }
  }, React.createElement(_components.span)))))), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "⋅"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.638891em",
      verticalAlign: "-0.208331em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t vlist-t2"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.3361079999999999em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-2.5500000000000003em",
      marginLeft: "0em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.03148em"
    }
  }, "k"), React.createElement(_components.span, {
    className: "mbin mtight"
  }, "−"), React.createElement(_components.span, {
    className: "mord mtight"
  }, "1"))))), React.createElement(_components.span, {
    className: "vlist-s"
  }, "​")), React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.208331em"
    }
  }, React.createElement(_components.span))))))))))), "\n", React.createElement(_components.h3, null, "¿Cómo encontramos unos buenos valores de K?"), "\n", React.createElement(_components.p, null, "Con una realimentación y un valor cualquiera podemos ya obtener una aproximación al control, pero pueden pasar varias cosas, que el sistema se haga inestable (algo que hay que evitar siempre, veremos ejemplos), que el sistema no se mueva en absoluto o que el sistema llegue al punto de referencia en un tiempo más o menos largo."), "\n", React.createElement(_components.p, null, "Para conseguir un buen control tenemos que ajustar K para llegar a la referencia en un tiempo no demasiado largo y sin que de muchos bandazos. Esto se puede conseguir de distintas formas:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A ojo: Aunque parezca mentira se hace mucho y es algo que no se suele enseñar en la universidad, esto se aprende con la práctica, lo veremos."), "\n", React.createElement(_components.li, null, "Auto-tuning: Viene a ser lo mismo que a ojo pero lo hace una máquina de una forma muy rápida, muchos controladores comerciales lo lleva incorporado de serie, son conectar apretar el botón y listo."), "\n", React.createElement(_components.li, null, "Analíticamente: Entramos en terreno pantanoso, para ello primero necesitamos modelar el sistema a controlar, para esta parte aún necesitamos más base."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
